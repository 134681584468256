// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.KhuyenMai_wrapper__cJM6u {
  padding: 10px 5px;
  flex: 1 1;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 5px;
}
.KhuyenMai_wrapper__cJM6u img {
  width: 100%;
  object-fit: cover;
  border-radius: 10px;
}
.KhuyenMai_wrapper__cJM6u .KhuyenMai_ma-khuyenMai__lLlVw {
  width: 100%;
}
.KhuyenMai_wrapper__cJM6u .KhuyenMai_ma-khuyenMai__lLlVw .KhuyenMai_child__NvGp2 {
  width: 100%;
  height: 45px;
  display: flex;
  align-items: center;
  gap: 5px;
  text-align: center;
  padding: 0;
}
.KhuyenMai_wrapper__cJM6u .KhuyenMai_ma-khuyenMai__lLlVw .KhuyenMai_child__NvGp2 .KhuyenMai_child-code__tIevf {
  flex: 3 1;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.3215686275);
  border-radius: 10px;
  padding: 15px 0;
  color: #fff;
  font-weight: 600;
  font-size: 2rem;
}
.KhuyenMai_wrapper__cJM6u .KhuyenMai_ma-khuyenMai__lLlVw .KhuyenMai_child__NvGp2 a {
  flex: 1 1;
  height: 100%;
  background-color: #1500d1;
  border-radius: 10px;
  padding: 15px 0;
  text-decoration: none;
  color: #fff;
}`, "",{"version":3,"sources":["webpack://./src/pages/KhuyenMai/KhuyenMai.module.scss"],"names":[],"mappings":"AAAA;EACI,iBAAA;EACA,SAAA;EACA,WAAA;EACA,aAAA;EACA,sBAAA;EACA,QAAA;AACJ;AACI;EACI,WAAA;EACA,iBAAA;EACA,mBAAA;AACR;AAEI;EACI,WAAA;AAAR;AAEQ;EACI,WAAA;EACA,YAAA;EACA,aAAA;EACA,mBAAA;EACA,QAAA;EACA,kBAAA;EACA,UAAA;AAAZ;AAEY;EACI,SAAA;EACA,YAAA;EACA,mDAAA;EACA,mBAAA;EACA,eAAA;EACA,WAAA;EACA,gBAAA;EACA,eAAA;AAAhB;AAEY;EACI,SAAA;EACA,YAAA;EACA,yBAAA;EACA,mBAAA;EACA,eAAA;EACA,qBAAA;EACA,WAAA;AAAhB","sourcesContent":[".wrapper {\n    padding: 10px 5px;\n    flex: 1;\n    width: 100%;\n    display: flex;\n    flex-direction: column;\n    gap: 5px;\n\n    img {\n        width: 100%;\n        object-fit: cover;\n        border-radius: 10px;\n    }\n\n    .ma-khuyenMai {\n        width: 100%;\n\n        .child {\n            width: 100%;\n            height: 45px;\n            display: flex;\n            align-items: center;\n            gap: 5px;\n            text-align: center;\n            padding: 0;\n\n            .child-code {\n                flex: 3;\n                height: 100%;\n                background-color: #ffffff52;\n                border-radius: 10px;\n                padding: 15px 0;\n                color: #fff;\n                font-weight: 600;\n                font-size: 2rem;\n            }\n            a {\n                flex: 1;\n                height: 100%;\n                background-color: #1500d1;\n                border-radius: 10px;\n                padding: 15px 0;\n                text-decoration: none;\n                color: #fff;\n            }\n        }\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `KhuyenMai_wrapper__cJM6u`,
	"ma-khuyenMai": `KhuyenMai_ma-khuyenMai__lLlVw`,
	"child": `KhuyenMai_child__NvGp2`,
	"child-code": `KhuyenMai_child-code__tIevf`
};
export default ___CSS_LOADER_EXPORT___;
