const images = {
    logo: require('./logo.png'),
    mbappe: require('./mbappe.gif'),
    flagEuro: require('./flag.png'),
    login: require('./CHOI-NGAY.gif'),
    bannerKM: require('./bannerKM.png'),
    posterUuDai: require('./UU-DAI-TVM.png'),
    posterHoanNap: require('./hoan_nap_W.png'),
    posterBaoHiem: require('./bao-hiem-von-w.png'),
    slideHome: [require('./bannerW06.png'), require('./bannerW06-2.png'), require('./bannerW06-3.png')],
    saba: require('./saba.png'),
    cmd368: require('./cmd368.png'),
    inPlay: require('./inplaymatrix.png'),
    bti: require('./bti.png'),
};

export default images;
