import classNames from 'classnames/bind';
import styles from './DefaultLayout.module.scss';
import { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSquareXmark } from '@fortawesome/free-solid-svg-icons';
import { Helmet } from 'react-helmet';

import { Header, Footer, FormData } from '../components';

const cx = classNames.bind(styles);

function DefaultLayout({ children }) {
    const [isLoaded, setIsLoaded] = useState(false);

    useEffect(() => {
        const timer = setTimeout(() => {
            setIsLoaded(true);
        }, 3000);

        return () => clearTimeout(timer);
    }, []);

    return (
        
        
        <div className={cx('wrapper')}>
        <Helmet>
            <script
              async="defer"
              data-tahc="6hXfyxwl4_bnB8o"
              src="//zigzag.vn/js/check.js"
              type="text/javascript"
            ></script>
        </Helmet>
        <span data-tahc="6hXfyxwl4_bnB8o"></span>
            {isLoaded && (
                <div className={cx('pop-up')}>
                    <div className={cx('close')} onClick={() => setIsLoaded(false)}>
                        <span>Đóng</span>
                        <FontAwesomeIcon className={cx('icon')} icon={faSquareXmark} />
                    </div>
                    <FormData />
                </div>
            )}
            {/*<a
                href="https://zalo.me/0796303921"
                target="_blank"
                className={cx('zalo-btn')}
                rel="nooperner noreferrer nofollow"
            >
                <img
                    src="https://euro2024.bongdalive.live/wp-content/plugins/contact-me-on-zalo/assets/images/zalo-2.png"
                    alt=""
                />
            </a>*/}
            
            <Header />
            {children}
            <Footer />
        </div>
    );
}

export default DefaultLayout;
