import classNames from "classnames/bind";
import styles from "./FormData.module.scss";

import { useState } from "react";

const cx = classNames.bind(styles);
const scriptURL =
  "https://script.google.com/macros/s/AKfycbx3oVso7jHt0YGp_LAkXCcaWQ3Zfb8XG8cO3XPWIoE0QZ6_lhAAOeBPxaUSMwODOt3FiA/exec";

function FormData() {
  const [error, setError] = useState("");
  const [success, setSuccess] = useState(false);
  const [formData, setFormData] = useState({
    name: "",
    tel: "",
    email: "",
  });
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };
  const handleSubmit = (e) => {
    e.preventDefault();

    const isValidEmail = /^\w+@[a-zA-Z_]+?\.[a-zA-Z]{2,3}$/.test(
      formData.email
    );
    const isValidPhoneNumber = /^0\d{9,10}$/.test(formData.tel);

    if (!formData.tel && !formData.email) {
      setError("Vui lòng nhập số điện thoại.");
      setSuccess(false);
    } else if (!isValidEmail && formData.email) {
      setError("Email không hợp lệ.");
      setSuccess(false);
    } else if (!isValidPhoneNumber && formData.tel) {
      setError("Số điện thoại không hợp lệ.");
      setSuccess(false);
    } else {
      setError("Thành công! Chúng tôi sẽ liên hệ hỗ trợ sớm nhất.");
      setSuccess(true);
      setFormData({
        name: "",
        tel: "",
        email: "",
      });

      fetch(scriptURL, {
        method: "POST",
        body: JSON.stringify({ ...formData, url: window.location.href }),
        headers: {
          "Content-Type": "application/json",
        },
        mode: "no-cors",
      })
        .then((res) => {
          console.log("SUCCESSFULLY SUBMITTED");
        })
        .catch((err) => console.log(err));
    }
  };

  return (
    <div className={cx("wrapper")}>
      {/* ----------formData---------- */}
      <form className={cx("form")} onSubmit={handleSubmit}>
        <h3>Nhận Khuyến Mãi trực tiếp</h3>
        <div className={cx("form-item")}>
          <span>Tên của bạn:</span>
          <input
            type="text"
            name="name"
            placeholder="Nhập tên của bạn"
            value={formData.name}
            onChange={handleChange}
          />
        </div>
        <div className={cx("form-item")}>
          <span>Số điện thoại:</span>
          <input
            type="tel"
            name="tel"
            placeholder="Nhập sđt của bạn"
            value={formData.tel}
            onChange={handleChange}
          />
        </div>
        {/* <div className={cx("form-item")}>
          <span>Email của bạn:</span>
          <input
            type="email"
            name="email"
            placeholder="Nhập email của bạn"
            value={formData.email}
            onChange={handleChange}
          />
        </div> */}
        <span className={cx("error", { success: success })}>{error}</span>
        <button type="submit" disabled={success}>
          Nhận
        </button>
      </form>
      {/* ----------formData---------- */}
    </div>
  );
}

export default FormData;
