// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.FormData_wrapper__9cHJ3 {
  width: 100%;
  margin-top: 10px;
}
.FormData_wrapper__9cHJ3 .FormData_form__5zqHy {
  width: 100%;
  min-height: 100px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 5px;
  background-color: #fff;
  border-radius: 10px;
  padding: 10px;
  margin-bottom: 10px;
}
.FormData_wrapper__9cHJ3 .FormData_form__5zqHy h3 {
  margin: 0;
  padding: 10px 20px;
  background-color: #0006bd;
  font-family: var(--font-roboto);
  color: #fff;
  border-radius: 10px;
}
.FormData_wrapper__9cHJ3 .FormData_form__5zqHy .FormData_form-item__2LZ5A {
  width: 100%;
  display: flex;
}
.FormData_wrapper__9cHJ3 .FormData_form__5zqHy .FormData_form-item__2LZ5A span {
  flex: 1 1;
  padding: 10px;
}
.FormData_wrapper__9cHJ3 .FormData_form__5zqHy .FormData_form-item__2LZ5A input {
  flex: 2 1;
  border: none;
  background-color: rgba(192, 198, 255, 0.1254901961);
  padding: 0 10px;
  border-radius: 10px;
}
.FormData_wrapper__9cHJ3 .FormData_form__5zqHy .FormData_error__-r-ub {
  color: red;
}
.FormData_wrapper__9cHJ3 .FormData_form__5zqHy .FormData_success__f\\+yjG {
  color: green;
}
.FormData_wrapper__9cHJ3 .FormData_form__5zqHy button:disabled {
  background: #575454;
}
.FormData_wrapper__9cHJ3 .FormData_form__5zqHy button {
  padding: 5px 50px;
  border-radius: 10px;
  background-color: rgb(205, 0, 0);
  color: #fff;
  font-size: 2rem;
  border: none;
}`, "",{"version":3,"sources":["webpack://./src/layouts/components/FormData/FormData.module.scss"],"names":[],"mappings":"AAAA;EACE,WAAA;EACA,gBAAA;AACF;AACE;EACE,WAAA;EACA,iBAAA;EACA,aAAA;EACA,sBAAA;EACA,mBAAA;EACA,QAAA;EACA,sBAAA;EACA,mBAAA;EACA,aAAA;EACA,mBAAA;AACJ;AACI;EACE,SAAA;EACA,kBAAA;EACA,yBAAA;EACA,+BAAA;EACA,WAAA;EACA,mBAAA;AACN;AAEI;EACE,WAAA;EACA,aAAA;AAAN;AACM;EACE,SAAA;EACA,aAAA;AACR;AACM;EACE,SAAA;EACA,YAAA;EACA,mDAAA;EACA,eAAA;EACA,mBAAA;AACR;AAGI;EACE,UAAA;AADN;AAGI;EACE,YAAA;AADN;AAII;EACE,mBAAA;AAFN;AAKI;EACE,iBAAA;EACA,mBAAA;EACA,gCAAA;EACA,WAAA;EACA,eAAA;EACA,YAAA;AAHN","sourcesContent":[".wrapper {\n  width: 100%;\n  margin-top: 10px;\n\n  .form {\n    width: 100%;\n    min-height: 100px;\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    gap: 5px;\n    background-color: #fff;\n    border-radius: 10px;\n    padding: 10px;\n    margin-bottom: 10px;\n\n    h3 {\n      margin: 0;\n      padding: 10px 20px;\n      background-color: #0006bd;\n      font-family: var(--font-roboto);\n      color: #fff;\n      border-radius: 10px;\n    }\n\n    .form-item {\n      width: 100%;\n      display: flex;\n      span {\n        flex: 1;\n        padding: 10px;\n      }\n      input {\n        flex: 2;\n        border: none;\n        background-color: #c0c6ff20;\n        padding: 0 10px;\n        border-radius: 10px;\n      }\n    }\n\n    .error {\n      color: red;\n    }\n    .success {\n      color: green;\n    }\n\n    button:disabled {\n      background: #575454;\n    }\n\n    button {\n      padding: 5px 50px;\n      border-radius: 10px;\n      background-color: rgb(205, 0, 0);\n      color: #fff;\n      font-size: 2rem;\n      border: none;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `FormData_wrapper__9cHJ3`,
	"form": `FormData_form__5zqHy`,
	"form-item": `FormData_form-item__2LZ5A`,
	"error": `FormData_error__-r-ub`,
	"success": `FormData_success__f+yjG`
};
export default ___CSS_LOADER_EXPORT___;
