import classNames from "classnames/bind";
import styles from "./Footer.module.scss";

import { NavLink } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCalendarDays,
  faCircleNodes,
  faHome,
  faTicket,
} from "@fortawesome/free-solid-svg-icons";

import images from "../../../acssets/images";
import config from "../../../config";

const cx = classNames.bind(styles);

function Footer() {
  return (
    <div className={cx("wrapper")}>
      <NavLink
        to={config.routes.home}
        className={(nav) => cx("nav-child", { selected: nav.isActive })}
      >
        <FontAwesomeIcon className={cx("icon")} icon={faHome} />
        <span>Trang Chủ</span>
      </NavLink>
      <a href={process.env.REACT_APP_AFF_URL} className={cx("nav-child")}>
        <FontAwesomeIcon className={cx("icon")} icon={faTicket} />
        <span>KM Khác</span>
      </a>
      <a href={process.env.REACT_APP_AFF_URL} className={cx("nav-main")}>
        <div className={cx("login")}>Đăng Ký</div>
        <img src={images.login} alt="" />
      </a>
      <NavLink
        to={config.routes.lichThiDau}
        className={(nav) => cx("nav-child", { selected: nav.isActive })}
      >
        <FontAwesomeIcon className={cx("icon")} icon={faCalendarDays} />
        <span>Lịch Euro</span>
      </NavLink>
      <NavLink
        to={config.routes.pages3}
        className={(nav) => cx("nav-child", { selected: nav.isActive })}
      >
        <FontAwesomeIcon className={cx("icon")} icon={faCircleNodes} />
        <span>KQ Euro</span>
      </NavLink>
    </div>
  );
}

export default Footer;
