import { Home, LichThiDau, Pages3, KhuyenMai } from '../pages';
import config from '../config';

const publicRoutes = [
    { path: config.routes.home, component: Home },
    { path: config.routes.lichThiDau, component: LichThiDau },
    { path: config.routes.pages3, component: Pages3 },
    { path: config.routes.khuyenMai, component: KhuyenMai },
    // Mặc định nếu ko có layout => render ra DefaultLayout
    //....
];

// private những page cần đăng nhập mới hiển thị
const privateRoutes = [];

export { publicRoutes, privateRoutes };
