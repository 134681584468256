// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Slide_wrapper__95Lvs {
  position: relative;
  width: 100%;
  aspect-ratio: 50/15;
  margin-bottom: 10px;
  margin-top: 10px;
}

.Slide_img__gVwNJ {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 10px;
  border: 1px solid #00eaff;
}

.Slide_next__BJczP,
.Slide_prev__KxMLf {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
  color: #fff;
  background-color: rgba(0, 0, 0, 0.4705882353);
  padding: 10px 15px;
  border-radius: 999px;
  border: none;
  font-size: 2rem;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
}

.Slide_next__BJczP {
  right: 10px;
}

.Slide_prev__KxMLf {
  left: 10px;
}`, "",{"version":3,"sources":["webpack://./src/layouts/components/Slide/Slide.module.scss"],"names":[],"mappings":"AAAA;EACI,kBAAA;EACA,WAAA;EACA,mBAAA;EACA,mBAAA;EACA,gBAAA;AACJ;;AAEA;EACI,WAAA;EACA,YAAA;EACA,iBAAA;EACA,mBAAA;EACA,yBAAA;AACJ;;AAEA;;EAEI,kBAAA;EACA,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,WAAA;EACA,YAAA;EACA,WAAA;EACA,6CAAA;EACA,kBAAA;EACA,oBAAA;EACA,YAAA;EACA,eAAA;EACA,QAAA;EACA,2BAAA;EACA,eAAA;AACJ;;AAEA;EACI,WAAA;AACJ;;AAEA;EACI,UAAA;AACJ","sourcesContent":[".wrapper {\n    position: relative;\n    width: 100%;\n    aspect-ratio: 50 / 15;\n    margin-bottom: 10px;\n    margin-top: 10px;\n}\n\n.img {\n    width: 100%;\n    height: 100%;\n    object-fit: cover;\n    border-radius: 10px;\n    border: 1px solid #00eaff;\n}\n\n.next,\n.prev {\n    position: absolute;\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    width: 30px;\n    height: 30px;\n    color: #fff;\n    background-color: #00000078;\n    padding: 10px 15px;\n    border-radius: 999px;\n    border: none;\n    font-size: 2rem;\n    top: 50%;\n    transform: translateY(-50%);\n    cursor: pointer;\n}\n\n.next {\n    right: 10px;\n}\n\n.prev {\n    left: 10px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `Slide_wrapper__95Lvs`,
	"img": `Slide_img__gVwNJ`,
	"next": `Slide_next__BJczP`,
	"prev": `Slide_prev__KxMLf`
};
export default ___CSS_LOADER_EXPORT___;
