import classNames from "classnames/bind";
import styles from "./Pages3.module.scss";
import React, { useEffect, useState } from "react";
import moment from "moment";
import { Helmet } from "react-helmet";

const cx = classNames.bind(styles);

function Pages3() {
  const [matches, setMatches] = useState([]);

  useEffect(() => {
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    const raw = JSON.stringify({
      id: "v2y8m4zh3kql074",
    });

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch("https://api.5goall.com/resultsByLeague", requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (Array.isArray(result)) {
          const allMatches = result.flatMap((round) => round.matches);
          setMatches(allMatches);
        } else {
          console.error("Invalid data format:", result);
        }
      })
      .catch((error) => console.error(error));
  }, []);

  const formatDate = (timestamp) => {
    return moment.unix(timestamp).format("HH:mm - DD/MM/YYYY");
  };

  return (
    <>
      <Helmet>
        <title>Kết quả bóng đá Euro hôm nay</title>
        <meta name="description" content="Kết quả bóng đá Euro hôm nay" />
      </Helmet>
      <div className={cx("wrapper")}>
        <div className={cx("match")}>
          <h4>Kết quả Euro hôm nay</h4>
          <div className="football-results">
            {matches.length > 0 ? (
              matches.map((match, index) => (
                <div
                  className="matches-wrapper"
                  style={{ marginBottom: "15px" }}
                  key={index}
                >
                  <div
                    className={cx("teams")}
                    style={{ marginBottom: "5px", flexWrap: "wrap" }}
                  >
                    <div className="matches-date" style={{ width: "100%" }}>
                      {match?.match_time ? formatDate(match.match_time) : "N/A"}
                    </div>
                    <div className={cx("team-item")} style={{ width: "40%" }}>
                      <img src={match?.home_logo} alt={match?.home_name} />
                      <span>{match?.home_name}</span>
                    </div>
                    <span
                      className={cx("ft")}
                      style={{
                        width: "20%",
                        padding: "10px 5px",
                        textAlign: "center",
                      }}
                    >
                      {match?.home_scores[0] + " - " + match?.away_scores[0]}
                    </span>
                    <div className={cx("team-item")} style={{ width: "40%" }}>
                      <img src={match?.away_logo} alt={match?.away_name} />
                      <span>{match?.away_name}</span>
                    </div>
                  </div>

                  <div className={cx("infomation")}>
                    <span>
                      Thẻ vàng: {match?.home_scores[3] + match?.away_scores[3]}
                    </span>
                    <span>
                      Thẻ đỏ: {match?.home_scores[2] + match?.away_scores[2]}
                    </span>
                    <span>
                      Tổng góc: {match?.home_scores[4] + match?.away_scores[4]}
                    </span>
                  </div>
                </div>
              ))
            ) : (
              <p style={{ color: "#fff", textAlign: "center" }}>Đang tải...</p>
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default Pages3;
