import classNames from 'classnames/bind';
import styles from './Header.module.scss';

// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faFacebookMessenger } from '@fortawesome/free-brands-svg-icons';
// import { faFire, faFutbol, faMedal } from '@fortawesome/free-solid-svg-icons';
// import { NavLink } from 'react-router-dom';

import images from '../../../acssets/images';
// import config from '../../../config';
import { Slide } from '../../../layouts/components';

const cx = classNames.bind(styles);

function Header() {
    return (
        <div className={cx('wrapper')}>
            <Slide data={images.slideHome} />
            <div className={cx('tag')}>
                <div className={cx('tag-item')}>
                    <span>
                        <b>Không Giới Hạn</b>
                        <br />
                        Rút Tiền
                    </span>
                </div>
                <div className={cx('tag-item')}>
                    <span>
                        <b>Không Xác Minh</b>
                        <br />
                        Danh Tính
                    </span>
                </div>
                <div className={cx('tag-item')}>
                    <span>
                        <b>Hỗ Trợ Nhanh</b>
                        <br />
                        24/7
                    </span>
                </div>
            </div>
            <img src={images.flagEuro} className={cx('flag-euro')} alt="" />
            {/* <div className={cx('header')}>
                <NavLink to={config.routes.home} className={cx('logo')}>
                    <img src={images.logo} alt="logo-header" />
                </NavLink>
                <div className={cx('text')}>
                    <h3>
                        Khuyến mãi <span>độc quyền</span>
                    </h3>
                    <p>Khuyến mãi cực khủng mùa EURO</p>
                </div>
                <FontAwesomeIcon className={cx('icon')} icon={faFacebookMessenger} />
            </div> */}
            {/* <div className={cx('nav')}>
                <NavLink to={config.routes.home} className={(nav) => cx('nav-1', { selected: nav.isActive })}>
                    <span>Trang Chủ</span>
                    <FontAwesomeIcon className={cx('icon')} icon={faFire} />
                </NavLink>
                <NavLink to={config.routes.lichThiDau} className={(nav) => cx('nav-2', { selected: nav.isActive })}>
                    <span>Lịch Thi Đấu</span>
                    <FontAwesomeIcon className={cx('icon')} icon={faMedal} />
                </NavLink>
                <NavLink to={config.routes.pages3} className={(nav) => cx('nav-3', { selected: nav.isActive })}>
                    <span>Kết Quả Euro</span>
                    <FontAwesomeIcon className={cx('icon')} icon={faFutbol} />
                </NavLink>
            </div> */}
        </div>
    );
}

export default Header;
