import classNames from 'classnames/bind';
import styles from './Home.module.scss';

import { FormData, Slide } from '../../layouts/components';
import images from '../../acssets/images';

const cx = classNames.bind(styles);

function Home() {
    return (
        <div className={cx('wrapper')}>
            {/* <Slide data={images.slideHome} /> */}
            <div className={cx('uu-diem')}>
                <div className={cx('uu-diem_box')}>
                    <div className={cx('ud')}>
                        <div className={cx('title')}>
                            <h2>Hoàn nạp 2%</h2>
                            <p>Không giới hạn số lần nạp</p>
                        </div>
                        <img src={images.posterHoanNap} alt="mbappe" />
                    </div>
                    <a href={process.env.REACT_APP_AFF_URL} className={cx('link')}>
                        Chơi Ngay
                    </a>
                </div>
                <div className={cx('uu-diem_box')}>
                    <div className={cx('ud')}>
                        <div className={cx('title')}>
                            <h2 className={cx('ud-nap-dau')}>Tặng 150% nạp</h2>
                            <p>Đăng ký mới nạp đầu</p>
                        </div>
                        <img src={images.posterUuDai} alt="mbappe" />
                    </div>
                    <a href={process.env.REACT_APP_AFF_URL} className={cx('link')}>
                        Đăng Ký Ngay
                    </a>
                </div>
                <div className={cx('uu-diem_box')}>
                    <div className={cx('ud')}>
                        <div className={cx('title')}>
                            <h2>108.888.888 vnd</h2>
                            <p>Thưởng mốc nạp</p>
                        </div>
                        <img src={images.mbappe} alt="mbappe" />
                    </div>
                    <a href={process.env.REACT_APP_AFF_URL} className={cx('link')}>
                        Nhận Ngay
                    </a>
                </div>
                <div className={cx('uu-diem_box')}>
                    <div className={cx('ud')}>
                        <div className={cx('title')}>
                            <h2 className={cx('ud-nap-dau')}>Bảo hiểm 28%</h2>
                            <p>EURO yên tâm cược thả ga</p>
                        </div>
                        <img src={images.posterBaoHiem} alt="mbappe" />
                    </div>
                    <a href={process.env.REACT_APP_AFF_URL} className={cx('link')}>
                        Chơi Ngay
                    </a>
                </div>
            </div>
            <FormData />
            <div className={cx('gameTab')}>
                <h3>Hệ Sinh Thái Các Sảnh Hàng Đầu</h3>
                <div className={cx('gameTab-box')}>
                    <a href={process.env.REACT_APP_AFF_URL} className={cx('gameTab-item')}>
                        <img src={images.saba} alt="" />
                    </a>
                    <a href={process.env.REACT_APP_AFF_URL} className={cx('gameTab-item')}>
                        <img src={images.cmd368} alt="" />
                    </a>
                    <a href={process.env.REACT_APP_AFF_URL} className={cx('gameTab-item')}>
                        <img src={images.inPlay} alt="" />
                    </a>
                    <a href={process.env.REACT_APP_AFF_URL} className={cx('gameTab-item')}>
                        <img src={images.bti} alt="" />
                    </a>
                </div>
            </div>
            <div className={cx('uy-tin')}>
                <h3>Uy tín, tận tình và trách nhiệm</h3>
                <p>
                    Khuyến mãi hấp dẫn mới nhất dành cho anh em. <br /> Hỗ trợ trực tiếp 24/7
                </p>
            </div>
            <div className={cx('backPages')}></div>
        </div>
    );
}

export default Home;
