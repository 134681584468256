import classNames from 'classnames/bind';
import styles from './KhuyenMai.module.scss';

import images from '../../acssets/images';

const cx = classNames.bind(styles);

function KhuyenMai() {
    return (
        <div className={cx('wrapper')}>
            <img src={images.bannerKM} alt="" />
            <div className={cx('ma-khuyenMai')}>
                <div className={cx('child')}>
                    <p className={cx('child-code')}>4214213</p>
                    <a href="#">Dùng ngay</a>
                </div>
            </div>
        </div>
    );
}

export default KhuyenMai;
